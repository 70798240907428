.App {
	text-align: center;
	position: relative;
}

.demo-modal {
	width: 600px;
	position: absolute;
	z-index: 1;
	color: whitesmoke;
	background-color: rgba(30, 30, 30, 0.8);
	padding: 24px;
	border-radius: 6px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
	left: 50%;
	top: 15vh;
	transform: translateX(-50%);

	&__h1 {
		margin: 0 0 8px 0;
		font-size: 32px;
	}

	&__h2 {
		margin: 16px 0 8px 0;
		font-size: 24px;
	}

	&__p {
		margin: 0 0 8px 0;
		font-size: 18px;
	}

	&__close {
		background-color: transparent;
		color: whitesmoke;
		font-size: 16px;
		width: 100px;
		height: 24px;
		border: solid whitesmoke;
		border-width: 0 1px;
		margin: 16px 16px 8px 16px;
		border-radius: 3px;
		transition: background-color 0.2s;
		text-shadow: 4px 4px 7px rgba(0, 0, 0, 0.8);

		&:hover {
			background-color: rgba(255, 255, 255, 0.5);
		}
	}
}

.darken {
	position: absolute;
	z-index: 5;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.8);
}

.tour-step {
	position: absolute;
	z-index: 10;
	color: whitesmoke;
	padding: 24px;
	text-shadow: 4px 4px 7px rgba(0, 0, 0, 0.8);
	text-align: left;

	&__h1 {
		margin: 0 0 8px 0;
		font-size: 32px;
	}

	&__h2 {
		margin: 16px 0 8px 0;
		font-size: 24px;
	}

	&__p {
		margin: 0 0 8px 0;
		font-size: 18px;
		font-weight: bolder;
	}
}

.tour-highlight {
	z-index: 5;
	position: relative;
}


.tooltiptext {
	width: 160px;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 10;
}

.tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: black transparent transparent transparent;
}