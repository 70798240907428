@use '../../styles/partials/globals' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/colors' as *;
@use '../../styles/partials/fonts' as *;

.filter {
  width: 360px;

  &__header {
    position: sticky;
    top: 0;
  }

  &__title {
    color: $text-black;
    background-color: $header-white;
    padding: 1.5rem 2rem;
  }

  &__body {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 0.5rem;
    align-items: flex-start;

    &--title {
      padding-bottom: 0.75rem;
      font-weight: 500;
    }

    &--section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: start;
      padding-bottom: 24px;
      padding-right: 16px;
      padding-left: 16px;
    }
  }

  &__check {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__input {
    display: flex;
    align-items: flex-start;
    padding-bottom: 0.75rem;
  }
}

input[type='checkbox'] {
  margin-right: 0.1875rem;
  display: flex;
  height: 1.25rem;
}

label {
  text-align: start;
}