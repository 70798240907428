@font-face {
  font-family: 'Helvetica Neue';
  src: url('../../assets/fonts/Helvetica\ Neue\ Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Helvetiva Neue';
  src: url('../../assets/fonts/Helvetica\ Neue\ Bold\ Italic.ttf')
    format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetiva Neue';
  src: url('../../assets/fonts/HelveticaNeue\ Bold.ttf') format('truetype');
  font-weight: 600;
}
