@use './colors' as *;
@use './variables' as *;
@use './mixins' as *;
@use './fonts' as *;

* {
  box-sizing: border-box;
  margin: 0;
  border: 0;
  padding: 0;
}

html {
  font-size: 14px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
  color: #16191f;
}

h1 {
  font-size: 28px;
}

h2 {
  font-size: 18px;
}

h3 {
  font-size: 16px;
}

.caption {
  font-size: 12px;
}
