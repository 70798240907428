@use '../../styles/partials/globals' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/colors' as *;
@use '../../styles/partials/fonts' as *;

.main {
  width: 100%;
  flex-grow: 1;
  margin-top: 2.25rem;
  position: relative;

  &__body--section {
    background-color: white;
  }

  &__class--page {
    position: absolute;
    top: -1.25rem;
  }

  &--section__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 1.5rem;
    background-color: $header-white;
  }

  &__header {
    &--caption {
      color: $light-blue;
      text-decoration: underline;
    }
  }
}

.result__list {
  display: flex;
  // justify-content: space-between;
  padding: 0 2rem 1.5rem 2rem;
  flex-wrap: wrap;
  gap: 2rem;
}