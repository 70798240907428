@use '../styles/partials/globals' as *;
@use '../styles/partials/mixins' as *;
@use '../styles/partials/variables' as *;
@use '../styles/partials/colors' as *;

.result-card {
  width: 258px;
  margin-top: 1.5rem;
  border: 0.0625rem solid lightgray;
  box-shadow: 0 0.125rem 0.3125rem 0 rgba(19, 24, 44, 0.1);
  text-align: start;

  &__top {
    &--data-title {
      background-color: $header-white;
      padding: 1rem;
    }
  }

  &__detail {
    padding: 1rem;
    border-bottom: 0.0625rem solid lightgrey;

    &__container {
      font-size: 0.875rem;
    }

    &--header {
      display: flex;
      align-items: center;
      padding-bottom: 1rem;
    }

    &--logo {
      height: 2.1875rem;
      margin-right: 0.5rem;
    }

    &--logo-subtitle {}

    &--description {
      font-weight: 500;
    }
  }

  &__price-container {
    padding: 1rem;
    border-bottom: 0.0625rem solid lightgrey;

    &--price {
      font-size: 1.5rem;
      font-weight: 500;
      padding-bottom: 0.25rem;
    }

    &--subscription-length {
      color: gray;
      font-weight: 500;
    }

    &--bottom {
      color: gray;
      font-weight: 500;

      display: flex;
    }

    &--review-amount {
      //   display: flex;
    }

    &--usability-score {
      padding-right: 0.25rem;
    }
  }

  &__bottom {
    padding: 1rem;
    color: gray;
    font-weight: 500;

    &--published {}

    &--updated {}

    &--data-size {}
  }
}

.bottom {
  display: flex;

  &__title {
    color: $text-black;
    padding-right: 0.25rem;
  }
}